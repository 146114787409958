import * as React from 'react';
import './Home.css';
import { Box, Grid, Stack, TextField, Button, styled, Link } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import RemoveIcon from '@mui/icons-material/Remove';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import rephkLogo from "./assets/rephkLogo.gif";
import instagramIcon from "./assets/instagramIcon.png";
import phoneIcon from "./assets/phoneIcon.png";
// import artWorkImage1 from "./assets/artWorkImage1.jpeg";
// import artWorkImage2 from "./assets/artWorkImage2.jpeg";

const ColorButton = styled(Button)(() => ({
    fontWeight: 600,
    color: 'black',
    border: '1px solid black',
    '&:hover': {
        color: 'black',
        border: '1px solid black',
    },
}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
    // border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    padding: 0,
    borderBottom: '1px solid black',
    // backgroundColor:
    //     theme.palette.mode === 'dark'
    //         ? 'rgba(255, 255, 255, .05)'
    //         : 'rgba(0, 0, 0, .03)',
    // flexDirection: 'row-reverse',
    // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    //     transform: 'rotate(90deg)',
    // },
    // '& .MuiAccordionSummary-content': {
    //     marginLeft: theme.spacing(1),
    // },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    // borderTop: '1px solid black',
}));

const CssTextField = styled(TextField)({
    '& label.Mui-focused': {
        color: 'black',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: 'black',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: 'black',
        },
        '&:hover fieldset': {
            borderColor: 'black',
        },
        '&.Mui-focused fieldset': {
            borderColor: 'black',
        },
    },
});

const getMailToString = (receiveEmail, email, mobile, name, message) => {
    if (email && mobile) {
        return `mailto:${receiveEmail}?subject=New%20Contact%20From%20${email}&body=Email:%0A${email}%0AName:%0A${name}%0AMobile:%0A${mobile}%0AMessage:%0A${message}`
    }
    return ""
}

function Home() {
    const [expanded, setExpanded] = React.useState(false);
    const [founderIgUrl, setFounderIgUrl] = React.useState('');
    const [artWork1IgUrl, setArtWork1IgUrl] = React.useState('');
    const [contactIgUrl, setContactIgUrl] = React.useState('');
    const [receiveEmail, setReceiveEmail] = React.useState('');    
    const [emailInput, setEmailInput] = React.useState('');
    const [mobileInput, setMobileInput] = React.useState('');
    const [nameInput, setNameInput] = React.useState('');
    const [messageInput, setMessageInput] = React.useState('');
    const [mailToHref, setMailToHref] = React.useState('');

    const handleChange =
        (panel) => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };

    const messageOnChange = (event) => {
        if (event.target.value && event.target && event.target.value) {
            setMessageInput(event.target.value)
            if (event.target.value && emailInput) {
                const _email = emailInput
                const _name = nameInput
                const _message = event.target.value
                const _mobile = mobileInput
                setMailToHref(getMailToString(receiveEmail, _email, _mobile, _name, _message))
            } else {
                setMailToHref("")
            }
        } else {
            setMessageInput("")
            setMailToHref("")
        }
    }

    const nameOnChange = (event) => {
        if (event.target.value && event.target && event.target.value) {
            setNameInput(event.target.value)
            if (event.target.value && emailInput) {
                const _email = emailInput
                const _name = event.target.value
                const _message = messageInput
                const _mobile = mobileInput
                setMailToHref(getMailToString(receiveEmail, _email, _mobile, _name, _message))
            } else {
                setMailToHref("")
            }
        } else {
            setNameInput("")
            setMailToHref("")
        }
    }

    const mobileOnChange = (event) => {
        if (event.target.value && event.target && event.target.value) {
            setMobileInput(event.target.value)
            if (event.target.value && emailInput) {
                const _email = emailInput
                const _name = nameInput
                const _message = messageInput
                const _mobile = event.target.value
                setMailToHref(getMailToString(receiveEmail, _email, _mobile, _name, _message))
            } else {
                setMailToHref("")
            }
        } else {
            setMobileInput("")
            setMailToHref("")
        }
    }

    const emailOnChange = (event) => {
        if (event.target.value && event.target && event.target.value) {
            setEmailInput(event.target.value)
            if (event.target.value && mobileInput) {
                const _email = event.target.value
                const _name = nameInput
                const _message = messageInput
                const _mobile = mobileInput
                setMailToHref(getMailToString(receiveEmail, _email, _mobile, _name, _message))
            } else {
                setMailToHref("")
            }
        } else {
            setEmailInput("")
            setMailToHref("")
        }
    }

    const sendBtnOnClick = () => {
        if (!mailToHref) {
            alert("Please Input your contact information.")
        }
    }

    // const instagramLink = require(`${process.env.PUBLIC_URL}/repConfig.json`)
    // console.log("instagramLink:", instagramLink)

    try {
        if (!founderIgUrl) {
            fetch(`${process.env.PUBLIC_URL}/repConfig.json`)
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                // console.log("instagramLink:", myJson)
                if (myJson && myJson.founderIg && myJson.artWork1Ig && myJson.contactIg && myJson.receiveEmail) {
                    setFounderIgUrl(myJson.founderIg)
                    setArtWork1IgUrl(myJson.artWork1Ig)
                    setContactIgUrl(myJson.contactIg)
                    setReceiveEmail(myJson.receiveEmail)
                }
            });
        }
    } catch (error) {
        console.log("fetch error:", error)
    }

    return (
        <div className="Home">
            <Box sx={{ flexGrow: 1 }}>
                <div className="main-container">
                    <div className="logo-container">
                        {/* <Box sx={{ bgcolor: 'Green' }}>
                            AAA
                        </Box> */}
                        <img src={rephkLogo} alt="logo" className="company-logo" />
                    </div>
                    <div className="content-container">
                        <Stack spacing={2}>
                            <div className="info-block">
                                <div className='info-title'>ADDRESS</div>
                                G/F, 25 Wellington Street<br />
                                Central<br />
                                Hong Kong<br />
                            </div>
                            <div className="info-block">
                                <div className='info-title'>SERVICES</div>
                                Personal Training<br />
                                Physiotherapy<br />
                                Sports massage<br />
                                Nutrition<br />
                            </div>
                            <div className="details-block">
                                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ backgroundColor: '#B39E8C' }}>
                                    <AccordionSummary
                                        expandIcon={
                                            expanded === 'panel1' ?
                                                <CircleIcon sx={{ color: 'black', fontSize: 15 }} />
                                                : <RemoveIcon sx={{ color: 'black', fontSize: 15 }} />
                                        }
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <div className='details-title'>FROM THE FOUNDER</div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='details-content'>
                                            <a href={founderIgUrl ? founderIgUrl : ""} target="_blank">
                                                <img src={instagramIcon} alt="ig" className="instagram-icon" />
                                            </a>

                                            <br /><br />
                                            Having studied art from a young age, Lynus sees fitness and art both as powerful tools of creative expression. As an artist, he thinks art has boundless possibilities - it can be deeply personal yet conversational, it can be done spontaneously or meticulously.
                                            <br /><br />
                                            The versatility and unpredictability of the gallery setting reflects Lynus’s own philosophical belief in the beauty of breaking routines. “Our body is a living sculpture that evolves day to day, just like our gym.” With this in mind, he started REP. - a new idea for a fitness space, an embodiment of his creative vision.
                                            <br /><br />
                                            Fueled by his passion in helping people build confidence through fitness, he envisions REP. as a lifestyle destination that drives positive conversations. “I want to build a gym where the guests feel energized and stimulated by the setting and people at REP. when they visit”.
                                            <br /><br />
                                            Going forward, whilst operational enhancement remains to be a continuous effort, REP. will keep creating new synergies through collaborations and innovations. On the constant quest for excellence in fitness, Lynus is never one to back down from a challenge.
                                            <br /><br />
                                            <br /><br />
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ backgroundColor: '#B39E8C' }}>
                                    <AccordionSummary
                                        expandIcon={
                                            expanded === 'panel2' ?
                                                <CircleIcon sx={{ color: 'black', fontSize: 15 }} />
                                                : <RemoveIcon sx={{ color: 'black', fontSize: 15 }} />
                                        }
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                    >
                                        <div className='details-title'>WHAT REP. STANDS FOR</div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='details-content'>
                                            These five REP.s set the foundation in building our purpose-driven model:
                                            <br />
                                            It’s what guides our on-going pursuit of excellence.
                                            <br /><br />
                                            <div className='details-title'>
                                                REPRESENT
                                            </div>
                                            We stand proud and strong with our community who all share a common vision.
                                            <br /><br />
                                            <div className='details-title'>
                                                REPUTATION
                                            </div>
                                            We pride ourselves in offering an unrivaled premium fitness experience.
                                            <br /><br />
                                            <div className='details-title'>
                                                REPAVE
                                            </div>
                                            We don’t follow rules. We see limitless possibilities of change.
                                            <br /><br />
                                            <div className='details-title'>
                                                REPOWER
                                            </div>
                                            We believe willpower is a mindset. Harness the power to reach your goal.
                                            <br /><br />
                                            <div className='details-title'>
                                                REPETITION
                                            </div>
                                            We believe the best way in honing a craft is through determination.

                                            <br /><br />
                                            <br /><br />
                                            <div className='details-title'>
                                                STORY
                                            </div>
                                            -
                                            <br />
                                            Hong Kong's Premier Personal Training Experience
                                            <br /><br />
                                            Disrupting the industry, REP. serves as a trailblazer that paves the way for a new generation of concept gyms. Merging the art of training with science-based rehabilitation, REP. is THE place where we help you realize your full potential and experience a new mode of body sculpting.
                                            <br /><br />
                                            We go above and beyond to fire up your motivation, challenge and push you to the limit.
                                            <br /><br />
                                            Here at REP., we create standards.

                                            <br /><br />
                                            <br /><br />
                                            <div className='details-title'>
                                                MISSION
                                            </div>
                                            -
                                            <br />
                                            Situated in the heart of the city, our two-storey gym is an ever-evolving space that defies convention. A fitness atelier, a studio and a creative lab in one - this hybrid triality is an architectural reflection of our brand philosophy. It is a collaborative space thoughtfully designed for our members to find their creativities - to inspire, and be inspired.
                                            <br /><br />
                                            With the belief that human form being an art creation, and means of expression, REP. is dedicated to guiding your body exploration journey.
                                            <br /><br />
                                            In the pursuit of excellence, we leave no stone unturned. REP. is here to unleash the limitless possibilities of change.
                                            <br /><br />
                                            We don’t follow. We REPresent.

                                            <br /><br />
                                            <br /><br />
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ backgroundColor: '#B39E8C' }}>
                                    <AccordionSummary
                                        expandIcon={
                                            expanded === 'panel3' ?
                                                <CircleIcon sx={{ color: 'black', fontSize: 15 }} />
                                                : <RemoveIcon sx={{ color: 'black', fontSize: 15 }} />
                                        }
                                        aria-controls="panel3a-content"
                                        id="panel3a-header"
                                    >
                                        <div className='details-title'>ARTWORKS & COLLABORATIONS</div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='details-content'>
                                            {/* <img src={artWorkImage1} alt="ig" className="artworks-image" /> */}
                                            <img src={process.env.PUBLIC_URL + '/artWorkImg1.png'} alt="ig" className="artworks-image" />
                                            <br />
                                            <a href={artWork1IgUrl ? artWork1IgUrl : ""} target="_blank">
                                                <img src={instagramIcon} alt="ig" className="instagram-icon" />
                                            </a>
                                            <br /><br />
                                            LITE – a new salad bar takeaway concept focuses on holistic wellness, debuts a fresh image at G/F 25 Wellington Street in Central. LITE provides a wide selection of nutritious, seasonal fresh foods and handpick ingredients to create a well-balanced bowl of salad, on-the-go smoothies and juices, in an eco-friendly and thoughtfully designed space. Customers can DIY their own selections of greens, proteins and dressings. The in-house kitchen team and Australian-based nutritionist Kristy Lau of LITE aim to promote conscious eating in this busy city, catering to people with a variety of different diets, with gluten-free, vegetarian, vegan and pescatarian options.
                                            <br /><br />
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ backgroundColor: '#B39E8C' }}>
                                    <AccordionSummary
                                        expandIcon={
                                            expanded === 'panel4' ?
                                                <CircleIcon sx={{ color: 'black', fontSize: 15 }} />
                                                : <RemoveIcon sx={{ color: 'black', fontSize: 15 }} />
                                        }
                                        aria-controls="panel4a-content"
                                        id="panel4a-header"
                                    >
                                        <div className='details-title'>CONTACT US</div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='details-content'>
                                            <div className="contact-block">
                                                <Stack direction="row" spacing={2}>
                                                    <div className='input-label'>Name</div>
                                                    <Box sx={{ width: 300 }}>
                                                        <CssTextField fullWidth id="nameTextInput" variant="outlined" size="small" value={nameInput} onChange={nameOnChange} />
                                                    </Box>
                                                </Stack>
                                                <br />
                                                <Stack direction="row" spacing={2}>
                                                    <div className='input-label'>Mobile</div>
                                                    <Box sx={{ width: 300 }}>
                                                        <CssTextField fullWidth id="mobileTextInput" variant="outlined" size="small" value={mobileInput} onChange={mobileOnChange} />
                                                    </Box>
                                                </Stack>
                                                <br />
                                                <Stack direction="row" spacing={2}>
                                                    <div className='input-label'>Email</div>
                                                    <Box sx={{ width: 300 }}>
                                                        <CssTextField fullWidth id="emailTextInput" variant="outlined" size="small" value={emailInput} onChange={emailOnChange} />
                                                    </Box>
                                                </Stack>
                                                <br />
                                                <Stack direction="row" spacing={2}>
                                                    <div className='input-label'>Message</div>
                                                    <Box sx={{ width: 300 }}>
                                                        <CssTextField fullWidth id="messageTextInput" variant="outlined" size="small" value={messageInput} onChange={messageOnChange} />
                                                    </Box>
                                                </Stack>
                                                <br />
                                                <Stack direction="row" spacing={2}>
                                                    <div className='input-label'></div>
                                                    <ColorButton
                                                        href={mailToHref ? mailToHref : ''}
                                                        variant="outlined"
                                                        onClick={sendBtnOnClick}
                                                    >
                                                        Send To Us
                                                    </ColorButton>

                                                </Stack>
                                                <br />
                                                <Stack direction="row" spacing={2}>
                                                    <a href={contactIgUrl ? contactIgUrl : ""} target="_blank">
                                                        <img src={instagramIcon} alt="ig" className="instagram-icon" />
                                                    </a>
                                                </Stack>
                                                <Stack direction="row" spacing={2}>
                                                    <img src={phoneIcon} alt="ig" className="instagram-icon" />
                                                    <div className='input-label'>3169 6666</div>
                                                </Stack>
                                            </div>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                            </div>
                            
                        </Stack>
                    </div>
                    <div className="footer-container">
                        {/* <Box sx={{ bgcolor: 'Green' }}>
                            AAA
                        </Box> */}
                    </div>
                </div>
            </Box>

        </div>
    );
}

export default Home;


